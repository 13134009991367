<template>
  <div>
    <persistent-modal-login :open_modal="open_modal_login" :key="update_modal_login" :redirect="redirect" />
  </div>
</template>
  
<script>
import PersistentModalLogin from '@/views/components/modal/PersistentModalLogin'

export default {
  name: "ForceLogin",
  components: {
    PersistentModalLogin
  },
  props: {
  },
  data() {
    return {
      open_modal_login: true,
      update_modal_login: false,
      redirect: {}
    };
  },
  created() {
    if (this.$route.params.redirect) this.redirect = this.$route.params.redirect
  },
  methods: {
  }
};
</script>